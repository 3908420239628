import React, { FC } from 'react';
import logoEclinic from '../../assets/branch/logo_eclinic_mono.png';
import './Footer.css'
import { mdiFacebook, mdiInstagram } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';

const Footer: FC = () => <footer className="eclinic-footer">
                            <div className='footer-container'>
                            <div className='footer-logo-container'>
                                <div className="footer-logo__image-wrapper">
                                    <Link to="/">
                                        <img className='footer__logo' src={logoEclinic} alt="Logo de eclinic" />
                                    </Link>  
                                </div>
                                <p>Gestión clínica hecha simple</p>  
                                <div className="footer-logo__icons-wrapper">
                                    <a href="https://www.facebook.com/eclinicdoctor">
                                        <Icon 
                                        path={mdiFacebook}
                                        title="eClicni menu"
                                        size={1.5}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="white"/>
                                    </a>
                                   <a href="https://www.instagram.com/eclinicdoctor/">
                                    <Icon 
                                        path={mdiInstagram}
                                        title="eClicni menu"
                                        size={1.5}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="white"/>
                                   </a>

                               {/*     <a href="https://twitter.com/agendactiva_app">
                                    <Icon 
                                        path={mdiLinkedin}
                                        title="eClicni menu"
                                        size={1.5}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color="white"/>
                                   </a> */}
                                   
                                </div>   
                            </div>
                            <div className="footer_nav__companies-container">
                                <h3>Compañia</h3>
                                <ul className="footer_nav-companies-entries" >
                                     <li><Link className="footer_entry" to="/frequent-questions">Preguntas frecuentes</Link></li>
                                    <li><a href='https://wa.me/573165406875' className="footer_entry">Hablar con un experto</a></li>
                                    <li><Link  className="footer_entry" to="/contact">Contacto</Link></li>
                                    <li><Link className="footer_entry" to="/price">Precio</Link></li>
                                    <li><Link className="footer_entry" to="/blog">Blog</Link></li>
                                </ul>
                            </div>
                            <div className="footer_nav__modules-container">
                                <h3>Módulos:</h3>
                                <ul className="footer_nav-modules-entries" >
                                    <li><Link className="footer_entry" to="/features">Historia clínica</Link></li>
                                    <li><Link className="footer_entry" to="/features">Agenda de citas</Link></li>
                                    <li><Link className="footer_entry" to="/features">Telemedicina</Link></li>
                                    <li><Link  className="footer_entry" to="/features">Prescripciones</Link></li>
                                    <li><Link  className="footer_entry" to="/features">Facturación y finanzas</Link></li>
                                    <li><Link  className="footer_entry" to="/features">Gestión de inventarios</Link></li>
                                </ul>
                            </div>
                            </div>
                            <div className="footer-divider"/>
                            <p className="copyRigth">eClinic! © 2024. Todos los derechos reservados. </p>
                        </footer> 

export default Footer;