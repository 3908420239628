import React, { FC, useState } from 'react';
import "./MainNav.css";
import { Link } from 'react-router-dom';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';

interface MainNavState{
    modulesVisiblity: boolean,
    resourcesVisiblity: boolean
};

const MainNav: FC = () => {
                            const [visibiltyState, setVisibilty] = useState({ modulesVisiblity: false, resourcesVisiblity: false } as MainNavState )
                            const [resourceHoverState, setResourceHoverState] = useState(false)
                            const [rHoverState, setRHoverState] = useState(false)
                            let toggleResourseVisiblity = () => { return { resourcesVisiblity: !visibiltyState.resourcesVisiblity, modulesVisiblity: false } }
                            let toggleModulesVisiblity = () => { return { resourcesVisiblity: false, modulesVisiblity:  !visibiltyState.modulesVisiblity } }
                            return (
                                    <div className="header-navbar-container">
                                        <div className='header-navbar__modules-button-container'> 
                                        <div  onClick={() => setVisibilty(toggleModulesVisiblity)} className="header-navbar__modules-button">
                                            <span onMouseEnter={() => {
                                                 setVisibilty(toggleModulesVisiblity)
                                                 setResourceHoverState(true)       
                                            }}
                                                  onMouseLeave={ () => {
                                                      setVisibilty(toggleModulesVisiblity)
                                                      setTimeout( () => setResourceHoverState( false ), 500 )
                                                  } } 
                                            >Módulos</span>
                                            <Icon className='modules-chevron-icon'
                                                                        path={mdiChevronDown}
                                                                        title="open Menu for Modules"
                                                                        size={1}
                                                                        horizontal
                                                                        vertical
                                                                        rotate={180}
                                                                        color="#0c3a5b"/>
                                            </div>
                                            { (visibiltyState.modulesVisiblity || resourceHoverState) && 
                                                <div className='modules-links-container' 
                                                onMouseEnter={() => {
                                                    setVisibilty(toggleModulesVisiblity)      
                                               }}
                                                onMouseLeave={ () => {
                                                                setVisibilty(toggleModulesVisiblity)
                                                                setTimeout( () => setResourceHoverState(false) ,500 )
                                                             } } >
                                                    <ul className='modules-links'>
                                                        <Link to="service_list"><li>Historia electrónica</li></Link>
                                                        <Link to="service_list"><li>Agenda de citas</li> </Link>
                                                        <Link to="service_list"><li>Telemedicina</li> </Link>
                                                        <Link to="service_list"><li>Prescripciones</li> </Link>
                                                        <Link to="service_list"><li>Facturación y finanzas</li> </Link>
                                                        <Link to="service_list"><li>Gestión de inventarios</li> </Link>
                                                    </ul> 
                                                </div>              
                                             }                         
                                        </div>
                                    <div className="header-navbar__resource-button-container">
                                        <div  onClick={() => setVisibilty( toggleResourseVisiblity() )} 
                                        className="header-navbar__resource-button">
                                        <span onMouseEnter={() => {
                                            setVisibilty( toggleResourseVisiblity() )
                                            setRHoverState(true)
                                        }} 
                                        onMouseLeave={ () => {
                                            setVisibilty( toggleResourseVisiblity() )
                                            setTimeout( () => 
                                            setRHoverState(false)
                                            , 500)         
                                        }
                                        }>Recursos</span>
                                        <Icon className='resource-chevron-icon'
                                                                            path={mdiChevronDown}
                                                                            title="open Menu for Modules"
                                                                            size={1}
                                                                            horizontal
                                                                            vertical
                                                                            rotate={180}
                                                                            color="#0c3a5b"/>
                                        </div>
                                        { (visibiltyState.resourcesVisiblity || rHoverState) && <div className='resources-links-container' 
                                                                onMouseLeave={() => setTimeout( () => { 
                                                                    setVisibilty( toggleResourseVisiblity() )
                                                                 }, 500)
                                                                 } 
                                                                 onMouseEnter={() => {
                                                                    setVisibilty( toggleResourseVisiblity() )
                                                                    setRHoverState(true)
                                                                }}   >
                                                                <ul className='resources-links'>
                                                                    <Link to="/frequent-questions"><li>Preguntas Frecuentes</li></Link> 
                                                                    <Link to="blog"><li>Blog</li></Link>
                                                                </ul> 
                                                            </div>
                                        }     
                                    </div>
                                    <Link  to="/price" className="header-navbar__price-button">Precios</Link>
                                    <a href="https://wa.me/573165406875" className="header-navbar__tell-to-expert-button">Hablar con un experto</a>
                                </div>
                          )
                        };
export default MainNav;
