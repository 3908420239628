import React, { FC } from 'react';
import BlogEntry from '../blog-entries/BlogEntry';
import './BlogList.css';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; 
//import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dentist from "../../assets/img/dentist_examining_overlay.png";
import legal from "../../assets/img/legalissues.jpg";
import finance from "../../assets/img/cash_eclinic.jpg";
import { Link } from 'react-router-dom';


/* 
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    centereMode: true,
    autoPlay: true,
    speed: 700,
    autoplaySpeed: 2000,
    centerPadding: '10px',
    cssEase: "linear",
    className: "blog-slider-container",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }; */

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

const BlogList: FC = () => <section className="blog-slider-container">
                            <Slider {...settings} >
                             <Link to={ `blog/1` }>
                                <BlogEntry title='' imageUrl={finance}> 
                                    Eclinic y la rentabilidad de su clínica/consultorio
                                </BlogEntry> 
                              </Link> 
                              <Link to={ `blog/2` }>
                                <BlogEntry title='' imageUrl={legal}> 
                                  Aspectos legales de la Historia Clínica Electrónica
                                </BlogEntry>
                              </Link>
                              <Link to={ `blog/3` }>
                                <BlogEntry title='' imageUrl={dentist}> 
                                  Conozca nuestro nuevo Odontograma
                                </BlogEntry> 
                              </Link>
                            </Slider>
                       </section>

export default BlogList;                        