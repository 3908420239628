import React, { useState } from 'react';
import './App.css';
import Header from './components/header/Header';
import MainPage from './pages/MainPage';
import Features from './components/features/Features';
import Contact from './components/contact/Contact';
import Price from './components/price/Price';
import Resources from './components/resources/Resources';
import Footer from './components/footer/Footer';
import ScheduleDemoEclinic from './components/scheduled-demo/ScheduleDemoEclinic'
import { Route, Switch } from 'react-router';
import ProductServiceFlexList from './components/eclinic_product-services-flex-list/ProductServicesFlexList';
import BlogPages from "./pages/BlogPages";
import BlogArticle1 from './components/blog-articles/BlogArticle1';
import BlogArticle2 from './components/blog-articles/BlogArticle2';
import BlogArticle3 from './components/blog-articles/BlogArticle3';
import BlogArticle4 from './components/blog-articles/BlogArticle4';
import TalkToExpert from './components/talk-to-expert/TalkToExpert';
import FrequentQuestions from "./components/frequent-questions/FrequentQuestions";
import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import EpaycoResponsePage from './components/epayco-response-page/EpaycoResponsePage';
import GA4React from "ga-4-react";
import CookieBanner from './components/cookies-banner/CookieBanner';
import CookiePolicy from "./components/cookie-policy/CookiePolicy";
import { Helmet } from "react-helmet";

const ga4react = new GA4React("G-HV86MG1D9D");
ga4react.initialize()

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

const advancedMatching = {
  /*   em: "cirs@hotmali.com" */
} as AdvancedMatching;


ReactPixel.init("512944236458993", advancedMatching, options);
ReactPixel.pageView();
ReactPixel.revokeConsent();

//const scriptCallPage = `<script>var loadBabel = function(url, callback) {var script = document.createElement('script');script.async = true;if (script.readyState) {script.onreadystatechange = function() {if (script.readyState == 'loaded' || script.readyState == 'complete') {script.onreadystatechange = null;callback(window, document);}};} else {script.onload = function() {callback(window, document);};}script.src = url;document.head.appendChild(script);};var getCookie = function(cname) {var objToday = new Date();var version = objToday.toISOString().split('T')[0].split('-').join('');var name = cname + '=';var decodedCookie = decodeURIComponent(document.cookie);var cookieArray = decodedCookie.split(';');for (var i = 0; i < cookieArray.length; i++) {var cookie = cookieArray[i];cookie = cookie.trim();if (cookie.indexOf(name) == 0) {return cookie.substring(name.length, cookie.length);}}return version;};var loadWidget = function(window, document) {var _cp = {"id":"aXpFpvzmMdoGxLa6FT7LfK61BCMLzDzVnNLfqulC3Ks","version":"1.1"};var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = true;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function(method) {if (method == '_getQueue') {return this.methods;} else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);} else {(this.methods = this.methods || []).push({arguments: arguments,});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}};loadBabel('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js', function() {return loadWidget(window, document);});</script>`

function App() {

  const [isCookiesAccepted, setIsCookieAccepted] = useState<boolean>(false);

  const toggleCookieAccept = () => {
    if (isCookiesAccepted) ReactPixel.grantConsent()
    setIsCookieAccepted(!isCookiesAccepted)
  }
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>eClinic - Especialistas. Gestión clínica hecha simple</title>
        <link rel="canonical" href="https://especialistas.eclinic.com.co" />
        <link rel="icon" href="%PUBLIC_URL%/favicon_eclinic.png" />
        <meta name="description" content="eClinic. Software todo en uno para la gestión de clínicas y consultorios. Historia clínica electrónica - Telemedicina - Agenda virtual - Facturación y Finanzas - Reportes de ley." />
      
      </Helmet>
      <Header />
      {/* <div dangerouslySetInnerHTML={{ __html: scriptCallPage }}></div> */}
      <Switch>
        <Route
          exact={true}
          path="/"
          component={MainPage} >
        </Route>
        <Route
          path="/demoeclinic"
          component={ScheduleDemoEclinic} >
        </Route>
        <Route
          path="/features"
          component={Features} >
        </Route>
        <Route
          path="/contact"
          component={Contact} >
        </Route>
        <Route
          path="/price"
          component={Price} >
        </Route>
        <Route
          path="/resources"
          component={Resources} >
        </Route>
        <Route
          path="/service_list"
          component={ProductServiceFlexList} >
        </Route>

        <Route
          path="/blog/1"
          component={BlogArticle1} >
        </Route>
        <Route
          path="/blog/2"
          component={BlogArticle2} >
        </Route>
        <Route
          path="/blog/3"
          component={BlogArticle3} >
        </Route>
        <Route
          path="/blog/4"
          component={BlogArticle4} >
        </Route>
        <Route
          path="/blog"
          component={BlogPages} >
        </Route>
        <Route
          path="/talk_to_expert"
          component={TalkToExpert} >
        </Route>
        <Route
          path="/frequent-questions"
          component={FrequentQuestions} >
        </Route>
        <Route
          path="/epayco_response"
          component={EpaycoResponsePage}>
        </Route>
        <Route
          path="/cookie_policy"
          component={CookiePolicy}>
        </Route>
      </Switch>
      {!isCookiesAccepted && <CookieBanner acceptCookieListener={toggleCookieAccept} />}
      <Footer />

    </div>
  );
}

export default App;


/* var loadBabel = function(url, callback) { 
  var script = document.createElement('script');
  script.async = true;if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState == 'loaded' || script.readyState == 'complete') {
        script.onreadystatechange = null;
        callback(window, document);}};} 
        else {
          script.onload = function() {
            callback(window, document);};}
            script.src = url;document.head.appendChild(script);};
            var getCookie = function(cname) 
            {var objToday = new Date();
              var version = objToday.toISOString().split('T')[0].split('-').join('');
              var name = cname + '=';
              var decodedCookie = decodeURIComponent(document.cookie);
              var cookieArray = decodedCookie.split(';');
              for (var i = 0; i < cookieArray.length; i++) {
                var cookie = cookieArray[i];cookie = cookie.trim();
                if (cookie.indexOf(name) == 0) {
                  return cookie.substring(name.length, cookie.length);}}
                  return version;};
                  var loadWidget = function(window, document) {
                    var _cp = {"id":"aXpFpvzmMdoGxLa6FT7LfK61BCMLzDzVnNLfqulC3Ks","version":"1.1"};
                    var cp = document.createElement('script');
                    cp.type = 'text/javascript';
                    cp.async = true;
                    cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(cp, s);
                    if (window.callpage) {
                      alert('You could have only 1 CallPage code on your website!');
                    } else {window.callpage = function(method) {
                      if (method == '_getQueue') {
                        return this.methods;} 
                        else if (method) {
                          if (typeof window.callpage.execute === 'function') {
                            return window.callpage.execute.apply(this, arguments);} else {
                              (this.methods = this.methods || []).push({arguments: arguments,});}}};
                              window.callpage.__cp = _cp;
                              window.callpage('api.button.autoshow');}};
                              loadBabel('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js', 
                              function() {return loadWidget(window, document);}); */




