import './LandingPageHeader.css'
import Typewriter from 'typewriter-effect';


const typeWritterOptions = {
    strings: ['Consultorio.', 'Clínica.'],
    autoStart: true,
    loop: true,
};


const LandingPageHeader = () => {
                                       
                                       return ( <div className='landing-page-head-container'>
                                            {/* <div>
                                                <img className='landing-page-head-container_img' src={headerImg} alt="Logo de eclinic" />
                                            </div> */}
  
                    
                                            <div className='landing-page-head-container_title'>
                                                <div className='landing-page-head-container_title-text-container'>
                                                    <h1>Optimice la Gestión de su </h1>
                                                    <Typewriter
                                                        options={typeWritterOptions}
                                                    />
                                                </div>
                                                
                                                <p>Gestione su clínica de forma simple con eClinic.</p>
                                                <div className='demoeclinic-container'>
                                                    <a 
                                                        href='https://wa.me/573165406875'
                                                        className='landing-page-head-container_button' 
                                                        >AGENDAR DEMO
                                                    </a>
                                                </div>                                                
                                             </div>
                                           </div> 
                                        )

                                    }
export default LandingPageHeader;