import React, { FC, useState, useRef } from "react";
import "./DrHelpDropDownList.css";
import { mdiPlus, mdiMinus } from '@mdi/js';
import Icon from '@mdi/react';
import DrHelpCheckItemList from "../drhelp-check-item-list/DrHelpCheckItemList";
import { CSSTransition } from 'react-transition-group';
import { content } from "../../models/FeaturesInPlans";

const DrHelpDropDownList: FC<{title: string, content: content[], isClicked: boolean, selectionListener: () => void}> = ({title, content, selectionListener, isClicked = false }) => {
                                                            const [expanded, setExpanded] = useState<boolean>(false);
                                                            const updateExpandedState = () => {
                                                                setExpanded( !expanded );
                                                                selectionListener();       
                                                            }

                                                            const heightRef = useRef<HTMLDivElement>(null);
                                                            const [dropDownHeight, setDropDownHeight] = useState<string>(`0px`)
                                                             
                                                            const dropDownContent = <div ref={heightRef} className="drhelp-drop-list__generated-container">
                                                                                    { 
                                                                                        content.map( (ct, index) => <DrHelpCheckItemList isFeature={ct.isFeaure} key={index}> 
                                                                                                                        { ct.feature }
                                                                                                                    </DrHelpCheckItemList>)
                                                                                    }
                                                                                    </div>;
                                                        
                                                            return (
                                                                <div className="drhelp-drop-list__container">
                                                                    <div className="drop-list-container" onClick={() => updateExpandedState() }>
                                                                        <Icon 
                                                                            className='drop-list-item__icon'
                                                                            path={(isClicked && expanded)? mdiMinus :mdiPlus}
                                                                            title="plus"
                                                                            size={1.2}
                                                                            horizontal
                                                                            vertical
                                                                            rotate={180}
                                                                            color="#19c1ce"/>
                                                                    <h3 className="drhelp-drop-list__title">{title}</h3>
                                                                </div>
                                                                {   
                                                                                                <CSSTransition
                                                                                                    in={ isClicked && expanded}
                                                                                                    timeout={600}
                                                                                                    classNames="drhelp-drop"
                                                                                                    onExiting={() => setDropDownHeight(`0px`)}
                                                                                                    onEntering={ () => {
                                                                                                        console.log(`${heightRef.current?.offsetHeight}px`)
                                                                                                        setDropDownHeight( `${heightRef.current?.offsetHeight}px` );
                                                                                                    }}
                                                                                                    unmountOnExit >
                                                                                                        <div className="drhelp-drop" style={{ height: dropDownHeight }}>
                                                                                                             { dropDownContent }                                           
                                                                                                        </div>
                                                                                                </CSSTransition>
                                                                
                                                                }
                                                            </div>
                                                            );
                                                        };

export default DrHelpDropDownList;                                    