import React, { useEffect, useRef } from 'react';
import "./WhatsAppDataCRM.css";

export interface WindowsWithWhatsAppDataCRM extends Window {
    datacrm_whatsapp_config: string
}

const WhatsAppDataCRM = () => {
 
    let whatsappScript = useRef<HTMLDivElement>(null);

    useEffect( () => {  

    (window as any).datacrm_whatsapp_config ={"mobile":"573165406875","asigned_user_name":"asistentemarketing","empresa":"https://server3.datacrm.la/datacrm/doctorhelpsas/webservice.php"}
    
    const script = document.createElement('script');
      
    script.src = "https://integraciones.datacrm.la/datacrm/whatsapp_button/wpp_button.min.js";
    script.async = true;
    document.body.appendChild(script);

   return () => {
     document.body.removeChild(script);
   }
    },
    [])
    return(
        <div ref={whatsappScript}/>
    );
} 

export default WhatsAppDataCRM;