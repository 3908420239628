import React, { FC } from 'react';
import "./BlogArticleN.css";
import forOdontogram from "../../assets/img/dentist_examining_overlay.png";

interface BlogArticleArgs {
    title: string, 
    imagePath: string, 
    description: string,
    publishedAt: string 
}

const BlogArticle4: FC<BlogArticleArgs> = ({title, imagePath, description, publishedAt}) => <article className="blog-article-section">
                                                <h2 className="blog-article-section__title">{ 
                                                    `Conozca nuestro nuevo Odontograma`
                                                }</h2>
                                                <p className="blog-article-section__publish-date">{
                                                    `PUBLICADO ABRIL 01, 2019 POR ADMINISTRACION DOCTORHELP!`
                                                }</p>
                                                 <div className="blog-article-section__img-container">
                                                    <img className="blog-article-section__img" src={ forOdontogram } alt={ title } />
                                                </div>
                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Sabemos lo importante que es en el sector odontológico contar con la herramienta de odontograma
                                                         para visualizar el diagnóstico general realizado al paciente. Es por esto que hemos venido trabajando 
                                                         en entregarles una excelente implementación de Odontograma gráfico en eClinic.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Con toda la potencia en configurabilidad de nuestra solución, usted podrá determinar cómo quiere 
                                                        que luzcan las convenciones que desea utilizar en su odontograma, si bien nosotros hemos implementado 
                                                        las convenciones más comunes
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Conservando la línea de diseño de nuestro producto, que lo hace una de las aplicaciones más amigables 
                                                        con el usuario, este Ontograma gráfico se adaptará de manera fluida con el dispositivo que use para 
                                                        visualizarlo.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Seguimos trabajando para traer un producto que se adapte a las necesidades de cualquier sector médico, 
                                                        en este caso, puntualmente para el sector odontológico.
                                                    ` }</p>
                                                </div>

                                            </article>;

export default BlogArticle4; 