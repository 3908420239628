import BlogEntries from '../models/BlogEntries';
import legal from "../assets/img/legalissues.jpg";
import financeRoom from "../assets/img/cash_eclinic.jpg";
import retentionEclinic from "../assets/img/doctor_checking_overlay.png";
import dentist from "../assets/img/dentist_examining_overlay.png";

const blogEntries: BlogEntries[] = [
    {
        title: "eClinic y la rentabilidad de su clínica/consultorio",
        imagePath: financeRoom,
        description: "Una aplicación es un sistema que recibe datos y genera información. Esto significa que, a ese sistema, a diario le estamos ingresando un conjunto de datos que representan un proceso y que como resultado debiéramos esperar información sobre el mismo proceso. Información que nos ayudará en la toma de decisiones. Pues bien, eso buscamos con eClinic.",
        publishedAt: "PUBLICADO MAYO 02, 2019 POR ADMINISTRACION DOCTORHELP!",
        index: 1
    },
    {
        title: "Aspectos legales de la Historia Clínica Electrónica",
        imagePath: legal,
        description: 'La resolución 1995 de 1999 definió la Historia Clínica como el documento en el cual "se registran cronológicamente las condiciones de salud del paciente, los actos médicos y los demás procedimientos ejecutados por el equipo de salud que interviene en su atención". La norma también define algunas características fundamentales de la Historia Clínica. Las repasaremos en este artículo al tiempo que le indicamos cómo están implementadas en AgendActiva para el correcto cumplimiento de la norma',
        publishedAt: "PUBLICADO ABRIL 01, 2019 POR ADMINISTRACION DOCTORHELP!",
        index: 2
    },
        
    {
        title: "Política de retención de Historia Clínica Electrónica en eClinic",
        imagePath: retentionEclinic,
        description: "La Ley colombiana establece que cada institución médica o cada profesional de la salud debe almacenar copia de la Historia Clínica de sus pacientes por un periodo mínimo de 15 años, que se cuentan desde la última atención realizada al paciente. Describimos en este artículo los diferentes escenarios de retención de dicha información de Historia Clínica cuando usted adquiere eClinic.",
        publishedAt: "PUBLICADO DICIEMBRE 14, 2018 POR ADMINISTRACION DOCTORHELP!",
        index: 3
    },
    {
        title: "Conozca nuestro nuevo Odontograma",
        imagePath: dentist,
        description: "Sabemos lo importante que es en el sector odontológico contar con la herramienta de odontograma para visualizar el diagnóstico general realizado al paciente. Es por esto que hemos venido trabajando en entregarles una excelente implementación de Odontograma gráfico en eClinic.",
        publishedAt: "PUBLICADO FEBRERO 01, 2019 POR ADMINISTRACION DOCTORHELP!",
        index: 4
    },

];

export default blogEntries;