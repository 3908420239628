import React, { FC } from 'react';
import "./BlogArticleN.css";
import earning from "../../assets/img/cash_eclinic.jpg";

interface BlogArticleArgs {
    title: string, 
    imagePath: string, 
    description: string,
    publishedAt: string 
}

const BlogArticle1: FC<BlogArticleArgs> = ({title, imagePath, description, publishedAt}) => <article className="blog-article-section">
                                                <h2 className="blog-article-section__title">{ `
                                                    eClinic y la rentabilidad de su clínica/consultorio
                                                ` }</h2>
                                                <p className="blog-article-section__publish-date">{`
                                                    PUBLICADO MAYO 02, 2019 POR ADMINISTRACION DOCTORHELP!
                                                `}</p>
                                                 <div className="blog-article-section__img-container">
                                                    <img className="blog-article-section__img" src={ earning } alt={ title } />
                                                </div>
                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Una aplicación es un sistema que recibe datos y genera información. Esto significa 
                                                        que, a ese sistema, a diario le estamos ingresando un conjunto de datos que 
                                                        representan un proceso y que como resultado debiéramos esperar información sobre el
                                                         mismo proceso. Información que nos ayudará en la toma de decisiones. Pues bien, eso 
                                                         buscamos con AgendActiva.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Todos los usuarios de eClinic ingresarán a diario información sobre la atención 
                                                        a los pacientes: registrarán evoluciones, costo de los procedimientos prestados, 
                                                        pagos de los pacientes, movimientos de inventario causados por la atención médica, 
                                                        ingresos y egresos, servicios externos contratados, entre otros datos.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                       Luego, el resultado de esto se podría resumir en ver cómo se ha comportado nuestra 
                                                       clínica o consultorio, por ejemplo, en el aspecto financiero. ¿Qué ingresos y 
                                                       egresos tuve en el último mes? ¿Tuvimos pérdidas o ganancias? ¿Qué tanto me está 
                                                       afectando la cartera de mis pacientes? A través de diversos reportes usted podrá conocer, 
                                                       por ejemplo, la rentabilidad de los procedimientos que presta. Sumados todos los gastos 
                                                       (movimientos de inventario, pago a profesionales, costo de servicios externos contratados), 
                                                       ¿qué rentabilidad me da en promedio este procedimiento médico?
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Con eClinic usted podrá responder todas las preguntas que se hace sobre su clínica o
                                                         consultorio desde la perspectiva de los negocios. Al integrar todos los datos de su quehacer,
                                                          en una única solución, usted podrá conocer a detalle su negocio y se podrá orientar a tomar 
                                                          decisiones que mejoren la rentabilidad. ¿Qué tal si nos ponemos como meta determinado porcentaje 
                                                          de ganancias en todos los procedimientos? Pues bien, eClinic le ayudará a saber en qué casos 
                                                          no se está obteniendo la rentabilidad deseada, qué gastos o costos están afectando el comportamiento
                                                           financiero de su institución.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Nosotros podemos configurar cualquier reporte que desee ver en la aplicación con el fin de 
                                                        que tome las decisiones correctas siempre. Todo esto incluido en el precio de AgendActiva. 
                                                        Permita que sea este sistema de información quien digiera la información relevante para que
                                                         su gerencia sea lo más eficiente y eficaz posible.
                                                    ` }</p>
                                                </div>




                                            </article>;

export default BlogArticle1; 