import React, { FC } from 'react';
import "./ForWho.css";
import ForMedicalEntities from '../for-medical-entities/ForMedicalEntities';
import ForSpecialties from '../for-specialties/ForSpecialties';

const ForWho: FC = () => <section className="for-who-section">
                            <ForMedicalEntities />
                            <div className="for-who-section__vertical-divider"/>
                            <ForSpecialties />
                         </section>;

export default ForWho;
