import React, { FC } from 'react';
import "./ReviewInPrice.css";

const ReviewInPrice: FC = () => <section className="review-in-price-section-container">
                                    <article className="review-in-price-review-container">
                                       {/*  <div className="review-in-price-review__img">
                                        </div> */}
                                        <div className="review-in-price-review__review-text">
                                            <p>eclinic mejoró mi consulta, tengo más orden en mis historia clínica, optimizó mi trabajo ya que tengo todo en un solo lugar, desde inventario hasta radiografías que necesito.</p>
                                            <p className="review-in-price-review__doctor-name"><b>Dra. Mónica Paz - Periodóncia</b></p>
                                        </div>
                                    </article>
                                    <article className="review-in-price-review-container">
                                       {/*  <div className="review-in-price-review__img">
                                        </div> */}
                                        <div className="review-in-price-review__review-text">
                                            <p>El fácil manejo y claridad de la plataforma son claves para posibilitar un óptimo tratamiento terapéutico de los pacientes.</p>
                                            <p className="review-in-price-review__doctor-name"><b>Dra. Paola Santamaria - Psicología</b></p>
                                        </div> 
                                    </article> 
                                </section>;
export default ReviewInPrice;                                
