import React, { FC } from 'react';
import './EClinicDescription.css';
import features from '../../assets/img/sas_drhelp.svg';
import FeatureCheck from '../feature-check/FeatureCheck';
import systemIntegrations from '../../assets/img/other_integrations_systems_eclinic.svg';
import marketing from '../../assets/img/marketin_ eclini.svg';
import marketPlaceGif from '../../assets/videos/marketplace_eclinic_final.webm';

const featuresChecks: string[] = ['Acesso web sin instalaciones',`Alojamiento en la nube`,
                                  'Encriptación de la información','Copias de seguridad', 'Multidispositivo'];

const featuresMarketingChecks: string[] = ['Programe recordatorios personalizados.',`Envie mensajes automáticos por e-mail y SMS`,
                                  'Haga seguimiento a sus campañas de mercadeo.','Brinde promociones a sus pacientes, por motivo de cumpleaños, por temporada o planes de referidos.', 
                                  'Recuérdele la toma de medicamentos a sus pacientes y aumente la adherencia al tratamiento.'];                                  

const MarketplacePremiumAccessChecks: string[] = ['Obtenga un perfil digital',`Aumente su visibilidad`,
                                          'Atraiga nuevos pacientes','Reciba reviews y opiniones', 'Publique fotos y videos de su clínica', 
                                           'Publicidad a través de aliados'];                                  


const EClinicDescription: FC = () => <section className='eclinic-description-section'>
                                       <div className='eclinic-description-1-container' >
                                          <div className='eclinic-description-container'>
                                             <div className='featureimage' >
                                                <img className='eclinic_description__image' src={features} alt='Eclinic features'></img>    
                                             </div>
                                             <div className='feature'>
                                                <h4 className='eclinic-description-container__title'>Listo para su uso</h4>
                                                {/* <h4 className='eclinic-description-container__title'>eclinic brinda un software como servicio (SaaS) <br/> <span>especializado para el sector de la salud</span></h4> */}
                                                <p className='eclinic-description-container__text'>Le proporcionamos los servidores, el almacenamiento y un subdominio de su elección para que use eClinic, con solo ingresar a una dirección web.</p>
                                                <div className='featurechecks'>
                                                   { featuresChecks.map( feature => <FeatureCheck feature={feature} key={feature}/> ) }
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="eclinic-description-2-subsection">
                                          <div className="eclinic-description-2-container">
                                             <div className="eclinic-description-2-container__container">
                                                <img className="eclinic-description-2-container__img" src={systemIntegrations} alt="Other systems integrations"></img>
                                             </div>
                                             <div className="eclinic-description-2-container__description">
                                                <h2 className="eclinic-description-2-container__title" >Integración con otros sistemas</h2>
                                                <p className="eclinic-description-2-container__text">Intercambie información con plataformas de terceros, a través de protocolos HL7 FHIR, entre otros. Cumpla con los estándares de interoperabilidad del Ministerio de salud.</p>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="eclinic-description-3">
                                          <div className="eclinic-description-3-container">
                                             <div className="eclinic-description-3-container__description">
                                                <h2 className="eclinic-description-3-container__title" >Desde la primera atención  hasta la fidelización </h2>
                                                <p className="eclinic-description-3-container__text">Fortalezca las relaciones con sus pacientes gracias a las funciones de marketing de eClinic. </p>
                                                <div className="eclinic-description-3_features">
                                                   { featuresMarketingChecks.map( feature => <FeatureCheck feature={feature} key={feature}/> ) }
                                                </div>
                                             </div>
                                             <div className="eclinic-description-3-container__container">
                                                <img className="eclinic-description-2-container__img" src={marketing} alt="Other systems integrations"></img>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="eclinic-description-4">
                                          <div className="eclinic-description-4-container"> 
                                             <div className="eclinic-description-3-container__description">
                                                <h2 className="eclinic-description-3-container__title" >Acceso Premium a <br /> Marketplace de salud</h2>
                                                <p className="eclinic-description-3-container__text">Conéctese con más pacientes y sea parte de un ecosistema de salud en crecimiento. Beneficio exclusivo para clientes eClinic.</p>
                                                <div className="eclinic-description-3_features">
                                                   { MarketplacePremiumAccessChecks.map( feature => <FeatureCheck feature={feature} key={feature}/> ) }
                                                </div>
                                             </div>
                                             <div className="eclinic-description-3-container__container">
                                                <video className="eclinic-description-3__marketplace-video" 
                                                      src={marketPlaceGif} autoPlay muted loop>
                                          
                                                </video>
                                             </div>
                                          </div>
                                          
                                       </div>
                                     </section>

export default EClinicDescription;                                     
