import React, { FC } from 'react';
import './ForMedicalEntities.css';
import clinicIcon from "../../assets/icons/clinic.svg";
import consultRoom from "../../assets/icons/consulting_room.svg";

const ForMedicalEntities: FC = () => <article className="for-medical-entities-article">
                                       <div className="for-clinic">
                                           <div className="for-clinic__icon">
                                               <img className="for-clinic__icon-img" 
                                               src={consultRoom}
                                               alt="ícono de consultorio médico"/>
                                           </div>
                                           <h3 className="for-entity_title">Para Consultorios</h3>
                                       </div>  
                                       <div className="for-who-section__vertical-divider"></div>    
                                       <div className="for-consulting-room">
                                           <div className="for-clinic__icon">
                                                <img className="for-clinic__icon-img"
                                                 src={clinicIcon}
                                                 alt="ícono de clínicas"
                                                 />
                                           </div>
                                           <h3 className="for-specialty_title">Para Clínicas</h3>
                                       </div>   
                                     </article>;

export default ForMedicalEntities;                                     
