import React, { FC } from "react";
import FeatureCheck from "../feature-check/FeatureCheck";
import TellToExpertButton from "../tell-to-expert-button/TellToExpertButton";
import "./MoreQuestions.css"

const moreQuestionFeatures: string[] = ["Visualice una demostración",
                                        "Conozca opciones de precios",
                                        "Solicite soluciones a medida"]

const MoreQuestions: FC = () => <section className="more-questions-section">
                                    <div className="more-questions-section__title">
                                        <h2 className="more-questions-section__title-text">¿Tiene más preguntas?</h2>
                                        <p className="more-questions-section__subtitle">Contáctenos para brindarle asesoría sobre nuestros productos y soluciones.</p>
                                    </div>
                                    <div className="more-questions-section__features-expert">
                                        <div className="more-questions-section__features">
                                            { moreQuestionFeatures.map( (feature, index) => <FeatureCheck 
                                                                                                feature={feature} 
                                                                                                key={index}/> ) }        
                                        </div>
                                        <div className="more-questions-section__to-expert-button">
                                            <TellToExpertButton />
                                        </div>
                                    </div>
                            </section>;

export default MoreQuestions;