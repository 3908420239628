import React, { FC } from 'react';
import './BlogEntry.css';
import BlogEntryModel from '../../props_models/BlogEntryModel';

const BlogEntry: FC<BlogEntryModel> = ({ imageUrl, children }) => <article className="blog-container">
                                                                    {/* <img className="logo_eclinic-blog" src={ logo }></img> */}
                                                                    <div className='blog-container__img-container'>
                                                                        <img className='blog-container__img' src={ imageUrl } alt="imagen de porta del blog médico" />
                                                                    </div>
                                                                    <div className='blog-container__title'>
                                                                        <p>
                                                                            { children } 
                                                                        </p>
                                                                    </div>
                                                                  </article>

export default BlogEntry;        