import React, { FC } from 'react';
import "./BlogArticle.css";
import { Link } from 'react-router-dom';
import BlogEntries from '../../models/BlogEntries';


const BlogArticle: FC<BlogEntries> = ({title, imagePath, description, publishedAt, index}) => <article className="blog-article-section">
                                                <h2 className="blog-article-section__title">{ title }</h2>
                                                <p className="blog-article-section__publish-date">{publishedAt}</p>
                                                 <div className="blog-article-section__img-container">
                                                    <img className="blog-article-section__img" src={ imagePath } alt={ title } />
                                                </div>
                                                <div className="blog-article-section__description">
                                                    <p>{ description }</p>
                                                </div>
                                                <div className="blog-article-section__continue-reading">
                                                    <Link to={ `blog/` + index }>CONTINUAR LEYENDO</Link> 
                                                </div>
                                            </article>;

export default BlogArticle;                                          