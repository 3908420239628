import React, { FC } from 'react';
import "./DrHelpCheckItemList.css";
import { mdiCheckCircle } from '@mdi/js';
import { mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';

const DrHelpCheckItemList: FC<{isFeature: boolean}> = ({isFeature, children}) => <div className="check-item-list__container">
                                                                <div className='check-item-list__icon-container'>
                                                                <Icon 
                                                                    className='check-item-list__icon'
                                                                     path={isFeature? mdiCheckCircle: mdiCloseCircle}
                                                                     title="check feature"
                                                                     size={.6}
                                                                     horizontal
                                                                     vertical
                                                                     rotate={180}
                                                                     color={isFeature? "#19c1ce": "#FF0000"}/>
                                                                </div>
                                                                { children }
                                                                
                                                                    
                                                             </div>;

export default DrHelpCheckItemList;                                                             