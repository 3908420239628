import React, { useEffect, useRef } from 'react';
import "./EpaycoButton.css";
import Epayco from "../../assets/img/pay.png";

const epaycoButtonConfiguration = {
    dtepkey: "7022efdf587f83732a354268114c0f55",
    classButton: "epayco-button",
    tax: "0",
    taxIco: "NaN",
    currency: "cop",
    country: "CO",
    response: "http://eclinic.com.co/epayco_response",
    test:"false",
    epaycoButton: Epayco
} 

const dynamicPriceConfiguration: (arg: boolean) => { price: string, name: string, description: string } = (isMonth: boolean) => isMonth?  {
        price: "89000",
        name: "eclinic plan mi consultorio",
        description: "eclinic plan mi consultorio"
    } : {
        price: "961200",
        name: "Plan anual mi consultorio eClinico",
        description: "Plan anual mi consultorio eClinic"
    };



const EpaycoButton = ({isMonth}: {isMonth: boolean}) => {
    const scriptContainer = useRef<HTMLDivElement>(null);
    useEffect( () => {
        const priceConfiguration = dynamicPriceConfiguration(isMonth);
        const script = document.createElement('script');
        script.src = 'https://checkout.epayco.co/checkout.js'
        script.async = true;
        script.setAttribute( "data-epayco-key", epaycoButtonConfiguration.dtepkey ) 
        script.setAttribute( "class", epaycoButtonConfiguration.classButton )
        script.setAttribute( "data-epayco-amount", priceConfiguration.price )
        script.setAttribute( "data-epayco-tax",  epaycoButtonConfiguration.tax)
        script.setAttribute( "data-epayco-tax-ico", epaycoButtonConfiguration.taxIco )
        script.setAttribute( "data-epayco-tax-base", priceConfiguration.price )
        script.setAttribute( "data-epayco-name", priceConfiguration.name )
        script.setAttribute( "data-epayco-description", priceConfiguration.description )
        script.setAttribute( "data-epayco-currency", epaycoButtonConfiguration.currency )
        script.setAttribute( "data-epayco-country", epaycoButtonConfiguration.country )
        script.setAttribute( "data-epayco-external", "" )
        script.setAttribute( "data-epayco-response", epaycoButtonConfiguration.response )
        script.setAttribute( "data-epayco-confirmation", "" )
        script.setAttribute( "data-epayco-test", epaycoButtonConfiguration.test )     
        script.setAttribute( "data-epayco-button", epaycoButtonConfiguration.epaycoButton )
       
        scriptContainer.current?.childNodes.forEach( child => scriptContainer.current?.removeChild(child) );

        scriptContainer.current?.appendChild(script);
        
        return () => {
                  
            scriptContainer.current?.removeChild(script);
            
                }

    },[isMonth]);


    return <div className="epayco-button-container"  ref={scriptContainer}>
    </div>

}

export default EpaycoButton;
