import React, { FC, useState } from 'react';
import { mdiMenu, mdiClose, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import "./MainHamburgerMenu.css";
import { Link } from 'react-router-dom';
import TellToExpertButton from '../tell-to-expert-button/TellToExpertButton';
import logoEclinic from '../../assets/branch/eclinic_specialties_logo_poli.svg';
import { CSSTransition } from 'react-transition-group';

const MainHamburgerMenu: FC = () => {
                                        let [isMenuDisplayed, setMenuDisplay] = useState(false)
                                        const [ isModulesOpen, setModulesOpen ] = useState<boolean>(false);
                                        const [ isResourcesOpen, setResourcesOpen ] = useState<boolean>(false);
                                        const resetMenuState = () => {
                                            setMenuDisplay(!isMenuDisplayed);
                                            setModulesOpen(false)
                                            setResourcesOpen(false)
                                        }


                                        return (
                                            <div className="hamburger-menu-container"  >
                                                <div onClick={() => setMenuDisplay(!isMenuDisplayed)}>
                                                    <Icon className='hamburger-menu__main-page-icon'
                                                        path={mdiMenu}
                                                        title="availability"
                                                        size={1.75}
                                                        horizontal
                                                        vertical
                                                        rotate={180}
                                                        color="#0c3a5b"/>

                                                </div>
                                               
                                          {/*   { isMenuDisplayed && <div className="background-black-overlay"></div> } */}
                                            { isMenuDisplayed && <div className="hamburger-menu__options-container">

                                                <div className="hamburger-menu__header">
                                                    <div className="hamburger-menu__header-img-container">
                                                        <img src={logoEclinic} 
                                                        alt="logo del header del menu desplegable"
                                                        className="hamburger-menu__header-img" />
                                                    </div>
                                                    <div className="hamburger-menu__header-close-button"
                                                         onClick={() => setMenuDisplay(!isMenuDisplayed)}>
                                                        <Icon className='hamburger-menu__close-icon'
                                                                    path={mdiClose}
                                                                    title="close menu"
                                                                    size={1.75}
                                                                    horizontal
                                                                    vertical
                                                                    rotate={180}
                                                                    color="#cccccc"/>
                                                    </div>
                                                </div>

                                                   
                                                    <div className="hamburger-menu__menu-entries-container">
                                                        <div className="hamburger-menu__menu-item__container" 
                                                             onClick={() => setModulesOpen(!isModulesOpen)}>
                                                            <p className="hamburger-menu__menu-item ">Módulos</p>
                                                            
                                                            <CSSTransition
                                                                    in={ isModulesOpen } 
                                                                    timeout={500}
                                                                    classNames="hamburger-menu__down-icon-rotation">
                                                                    <div>
                                                                        <Icon className='hamburger-menu__down-icon'
                                                                                    path={mdiChevronDown}
                                                                                    title="open Menu for Modules"
                                                                                    size={1}
                                                                                    horizontal
                                                                                    vertical
                                                                                    rotate={180}
                                                                                    color="#0c3a5b"/>
                                                                     </div> 
                                                            </CSSTransition>   
                                                        </div>
                                                            <CSSTransition
                                                                    in={ isModulesOpen } 
                                                                    timeout={1000}
                                                                    classNames="hamburger-menu__modules-items-menu">
                                                                    <div className="hamburger-menu__modules-items-menu">
                                                                        <div className='modules-links-container-hamburger-menu'>
                                                                            <ul className='modules-links-hamburger-menu'>
                                                                                <Link to="service_list" onClick={() => resetMenuState()}><li>Historia electrónica</li></Link>
                                                                                <Link to="service_list" onClick={() => resetMenuState()}><li>Agendamiento de citas</li> </Link>
                                                                                <Link to="service_list" onClick={() => resetMenuState()}><li>Telemedicina</li> </Link>
                                                                                <Link to="service_list" onClick={() => resetMenuState()}><li>Prescripciones</li> </Link>
                                                                                <Link to="service_list" onClick={() => resetMenuState()}><li>Facturación y finanzas</li> </Link>
                                                                                <Link to="service_list" onClick={() => resetMenuState()}><li>Gestión de inventarios</li> </Link>
                                                                           </ul> 
                                                                        </div> 
                                                                     </div> 
                                                            </CSSTransition>  

                                                        <div className="hamburger-menu__menu-item__container" 
                                                             onClick={() => setResourcesOpen(!isResourcesOpen)}>
                                                            <p className="hamburger-menu__menu-item ">Recursos</p>

                                                            <CSSTransition
                                                                    in={ isResourcesOpen } 
                                                                    timeout={500}
                                                                    classNames="hamburger-menu__down-icon-rotation">
                                                                    <div>
                                                                        <Icon className='hamburger-menu__down-icon'
                                                                                    path={mdiChevronDown}
                                                                                    title="open Menu for Modules"
                                                                                    size={1}
                                                                                    horizontal
                                                                                    vertical
                                                                                    rotate={180}
                                                                                    color="#0c3a5b"/>
                                                                     </div> 
                                                            </CSSTransition>
                                                        </div>

                                                        <CSSTransition
                                                                    in={ isResourcesOpen } 
                                                                    timeout={1000}
                                                                    classNames="hamburger-menu__resources-items-menu">
                                                                    <div className="hamburger-menu__resources-items-menu">
                                                                         <div className="resources-container-hamburguer-menu">
                                                                            <ul className='resources-links-hamburguer-menu'>
                                                                                <Link to="/frequent-questions" onClick={() => resetMenuState()}><li>Preguntas Frecuentes</li></Link> 
                                                                                <Link to="blog" onClick={() => resetMenuState()}><li>Blog</li></Link>
                                                                             </ul> 
                                                                         </div>     
                                                                     </div> 
                                                            </CSSTransition>               
                                                    </div>
                                                    <div className="hamburger-menu__price-button">
                                                        <Link  to="/price" 
                                                               onClick={() => resetMenuState()}>
                                                                   Precios
                                                        </Link>
                                                    </div>
                                                    <div onClickCapture={() => resetMenuState()}>
                                                        <TellToExpertButton/>
                                                    </div>  
                                            </div> }            
                                            </div>
                                        ) 
}



export default MainHamburgerMenu;
