import React, { FC, Fragment } from 'react';
import LandingPageHeader from '../components/landing-page-header/LandingPageHeader';
import EClinicDescription from '../components/eclinic-description/EClinicDescription';
import EClinicUserPreference from '../components/eclinic-user-preference/EClinicUserPreference';
import CustomerReviewList from '../components/customer-review-list/CustomerReviewList';
import BlogList from '../components/blog-entries/BlogList'
import EClinicAdvantage from '../components/eclinic-advantages/EClinicAdvantage';
import ProductServiceFlexList from "../components/eclinic_product-services-flex-list/ProductServicesFlexList"
import ForMedicalEntities from '../components/for-who/ForWho';
import AllServicesIcons from '../components/all-services-icons-section/AllServicesIcons';
import { Helmet } from 'react-helmet';
import WhatsAppDataCRM from '../components/whatsApp-datacrm-contact/WhatsAppDataCRM';

const MainPage: FC = () =><Fragment>
           <Helmet>
                <meta charSet="utf-8" />
                <title>eClinic - Especialistas. Gestión clínica hecha simple</title>
                <link rel="canonical" href="https://especialistas.eclinic.com.co" />
                <meta name="description" content="eClinic. Software todo en uno para la gestión de clínicas y consultorios. Historia clínica electrónica - Telemedicina - Agenda virtual - Facturación y Finanzas - Reportes de ley."/>
                
            </Helmet>
             <main className='main-content'>

                                <LandingPageHeader />
                                <EClinicAdvantage />
                                <WhatsAppDataCRM/>
                                <ForMedicalEntities />
                                <CustomerReviewList />
                                <AllServicesIcons />
                                {/*  <DummySlider /> */}
                                {/* <EClinicProducts /> */}
                                {/* <EclinicProductAndServicesGrid /> */}
                                <ProductServiceFlexList />
                                <EClinicDescription />
                                <EClinicUserPreference />
                                {/*    <ServicePlan /> */}
                                 <BlogList />
                            </main>
</Fragment>
;

export default MainPage;                            

