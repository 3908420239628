import React, { FC, Fragment } from 'react';
import logoEclinic from '../../assets/branch/eclinic_specialties_logo_poli.svg';
import './Header.css';
import { Link } from 'react-router-dom';
import MainNav from "../main-nav/MainNav";
import MainHamburgerMenu from "../main-hamburger-menu/MainHamburgerMenu";
import { useBreakPoint } from "../../hooks/BreakPoint";

const Home: FC = () => {

    const breakpoints = useBreakPoint();

    return (
        <Fragment>
            <header className='header-container' >
                <div className='header-logo-container'>
                    <Link to="/">
                        <img className='header-logo-container__img' src={logoEclinic} alt="Logo de eclinic" />
                    </Link>
                </div>
                <div className='header__menu'>
                   {/*  {
                        Object.keys( breakpoints ).map( match => {
                            console.log( `La ${match} is  ${breakpoints[match]}` )
                        } )
                    } */}

                    { breakpoints["sm"]?  <MainNav /> :
                                           <MainHamburgerMenu />
                    }
                    
                </div>
                
        </header>     
</Fragment>
    )
}
                            

export default Home;