import React, { FC } from 'react';
import './ForSpecialties.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import general from "../../assets/icons/medico_general.svg";
import nutrition from "../../assets/icons/nutricion.svg";
import odontology from "../../assets/icons/odontologia.svg";
import psychologist from "../../assets/icons/psicologo.svg";
import therapist from "../../assets/icons/terapeuta.svg";
import dermatologist from "../../assets/icons/especialistas.svg";



interface Specialty {
  name: string,
  picture: string
}

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 5000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false
      }
    }
  ]
};

const specialties: Specialty[] = [
  {
    name: "Médico general",
    picture: general
  },
  {
    name: "Psicólogo",
    picture: psychologist
  },
  {
    name: "Odontólogo",
    picture: odontology,
  },
  {
    name: "Nutricionista",
    picture: nutrition
  },
  {
    name: "Terapeuta",
    picture: therapist
  },
  {
    name: "Dermatólogo",
    picture: dermatologist
  }
];


const ForSpecialties: FC = () => <article className="for-medical-specialties-article">
                                   <div className="for-medical-specialties-article__title-container">
                                     <h3 className="for-medical-specialties-article__title" >Todas las<br/>Especialidades</h3>
                                   </div> 
                                   <div className="for-medical-specialties-article__specialties-slider">
                                    <Slider {...settings}>
                                      {  specialties.map( (specialty, index) => 
                                                            <div key={index}>
                                                              <div className="for-general">
                                                                  <img className="for-specialty__icon" 
                                                                  src={specialty.picture} 
                                                                  alt={`ícono de la especialidad de ${specialty.name}`}/>
                                                                  <h3 className="for-general_title">{specialty.name}</h3>
                                                              </div>
                                                            </div> 
                                                          )           
                                      }
                                      
                                        {/* <div>
                                          <div className="for-specialties">
                                              <div className="for-specialties__icon">
                                              </div>
                                              <h3 className="for-specialties_title">Especialistas</h3>
                                          </div> 
                                        </div>
                                        <div>
                                          <div className="for-general">
                                            <div className="for-general__icon">
                                            </div>
                                            <h3 className="for-general_title">Consultorio medicos</h3>
                                          </div>
                                        </div>   */}
                                    </Slider>
                                   </div>
                                 </article>;

export default ForSpecialties;    