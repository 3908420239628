import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { BreakpointProvider } from "./hooks/BreakPoint";
import ScrollToTop from './components/scroll-to-top/ScrollToTop';


const queries: { [media: string]: string } = {
  xs: '(max-width: 320px)',
  sm: '(min-width: 760px)',
  md: '(min-width: 1024px)',
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <BreakpointProvider queries={queries}>
        <ScrollToTop />
         <App />      
      </BreakpointProvider> 
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
