import FeaturesInPlans from "../models/FeaturesInPlans"


export const consultingRoomContent: FeaturesInPlans[] = [ 
      {  title: "Generales",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Usuario médico: <b>1</b></p>
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Número de asistentes: <b>ilimitados</b></p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Adaptación de logo y marca</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Ingreso desde cualquier lugar o dispositivo</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Copias de seguridad</p>,
                        },   
                        {
                              isFeaure: true,
                              feature: <p>Soporte técnico</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Capacitaciones</p>,
                        },                
                  ],
       },

       {  title: "Historia clínica electrónica",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Optimizada para atención presencial y virtual</p>, 
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Función de dictado</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Formatos personalizables para historia clínica (hasta 4)</p>,
                        },
                        {
                              isFeaure: true,
                              feature:  <p>Archivos adjuntos del paciente (limitado)</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Reportes RIPS y a medida</p>,
                        },  
                        {
                              isFeaure: true,
                              feature:  <p>Copias de seguridad</p>,
                        },                
                  ],
       },

       {  title: "Agendamiento de citas",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Agendamiento de citas unicas y recurrentes</p>, 
                        }, 
                        {
                              isFeaure: false,
                              feature: <p className="is-feature-included">Función de agenda para callcenter</p>,
                        },
                        {
                              isFeaure: true,
                              feature:  <p>Widget de agenda integrable son su página web</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Recordatorio de citas vía  correo y SMS</p>,
                        },  
                  ],
       },

       {  title: "Telemedicina",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Videoconsulta medico-paciente</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Sala de espera virtual</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Servicio ilimitado</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Función de grabación (valor adicional)</p>,
                        },               
                  ],
       },

       {  title: "Prescripciones médicas",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Medicamentos y exámenes de laboratorio</p>, 
                      }, 
                      {
                            isFeaure: true,
                            feature: <p>Incapacidades laborales</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Formularios personalizados</p>,
                      },
                      {
                            isFeaure: true,
                            feature:  <p>Envio de ordenes por correo electrónico</p>,
                      },
                      {
                            isFeaure: true,
                            feature:  <p>Recordatorio de toma de medicamentos via email y SMS</p>,
                      },               
                ],
     },

     {  title: "Facturación y finanzas",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Facturacion convencional</p>,
                      }, 
                      {
                            isFeaure: true,
                            feature: <p>Cuentas de cobro</p>,
                      },
                      {
                            isFeaure: true,
                            feature:    <p>Facturación prepagadas y convenios</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Cobros directos y copagos a pacientes</p>,
                      },
                      {
                            isFeaure: true,
                            feature:  <p>Cotización de procedimientos</p>,
                      },   
                      {
                        isFeaure: true,
                        feature: <p>Informes y reportes financieros</p>,
                      },             
                ],
     },

     {  title: "Gestión de inventarios",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Cargue de inventario</p>,
                      }, 
                      {
                            isFeaure: true,
                            feature: <p>Registro automático del consumo de insumos a medida que ocurre la atención</p>,
                      },
                                    
                ],
     },

     {  title: "Marketing",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Envio de mensajes y promociones</p>,
                      }, 
                      {
                            isFeaure: true,
                            feature:  <p>Campañas automatizadas via email y SMS</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Programación de recordatorios personalizados</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Seguimiento a campañas de mercadeo</p>,
                      },          
                ],
     },

     {  title: "Acceso a marketplace de salud",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Obtenga un Perfil profesional digital</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Aumente su visibilidad y atraiga nuevos pacientes</p>,
                        },
                        {
                              isFeaure: true,
                              feature:<p>Mejore su Reputacion en linea con reviews y opiniones de sus consultas</p>,
                        },
                        {
                              isFeaure: true,
                              feature:  <p>Publique fotos, videos y articulos (opcional)</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Reciba agendamiento online de citas</p>,
                        },   
                        {
                              isFeaure: true,
                              feature: <p>Brinde atención por teleconsulta</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Liste sus procedimientos</p>,
                        },   
                        {
                              isFeaure: true,
                              feature:  <p>Acepte pagos en linea</p>,
                        },
                        {
                              isFeaure: true,
                              feature:  <p>Publicidad a través de aliados</p>
                        },             
                  ],
       },
                 
 ]


 export const clinicContent: FeaturesInPlans[] = [ 
      {  title: "Generales",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Usuario médico: <b>1</b></p>, 
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Número de asistentes: <b>ilimitados</b></p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Número de sedes: <b>ilimitadas</b></p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Adaptación de logo y marca</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Ingreso desde cualquier lugar o dispositivo</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Copias de seguridad</p>,
                        },   
                        {
                              isFeaure: true,
                              feature: <p>Soporte técnico</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Capacitaciones</p>,
                        },                
                  ],
       },

       {  title: "Historia clínica electrónica",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Optimizada para atención presencial, virtual y domiciliaria</p>, 
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Función de dictado</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Formatos personalizables para historia clínica (ilimitado)</p>,
                        },
                        {
                              isFeaure: true,
                              feature:  <p>Archivos adjuntos del paciente (limitado)</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Reportes RIPS y a medida</p>,
                        },                 
                  ],
       },

       {  title: "Agendamiento de citas",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Agendamiento de citas unicas y recurrentes</p>, 
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Función de agenda para callcenter</p>,
                        },
                        {
                              isFeaure: false,
                              feature: <p className="is-feature-included">Widget de agenda integrable son su página web</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Recordatorio de citas vía  correo y SMS</p>,
                        },  
                  ],
       },

       {  title: "Telemedicina",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Videoconsulta medico-paciente</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Sala de espera virtual</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Servicio ilimitado</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Función de grabación (valor adicional)</p>,
                        },               
                  ],
       },

       {  title: "Prescripciones médicas",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Medicamentos y exámenes de laboratorio</p>, 
                      }, 
                      {
                            isFeaure: true,
                            feature: <p>Incapacidades laborales</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Formularios personalizados</p>,
                      },
                      {
                            isFeaure: true,
                            feature:  <p>Envio de ordenes por correo electrónico</p>,
                      },
                      {
                            isFeaure: true,
                            feature:  <p>Recordatorio de toma de medicamentos via email y SMS</p>,
                      },               
                ],
     },

     {  title: "Facturación y finanzas",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Facturacion convencional</p>,
                      }, 
                      {
                            isFeaure: true,
                            feature: <p>Cuentas de cobro</p>,
                      },
                      {
                            isFeaure: true,
                            feature:    <p>Facturación prepagadas y convenios</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Cobros directos y copagos a pacientes</p>,
                      },
                      {
                            isFeaure: true,
                            feature:  <p>Cotización de procedimientos</p>,
                      },   
                      {
                        isFeaure: true,
                        feature: <p>Informes y reportes financieros</p>,
                      },             
                ],
     },

     {  title: "Gestión de inventarios",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Cargue de inventario</p>,
                      }, 
                      {
                            isFeaure: true,
                            feature: <p>Registro automático del consumo de insumos a medida que ocurre la atención</p>,
                      },
                                    
                ],
     },

     {  title: "Marketing",
       contents: [ 
                      {
                            isFeaure: true,
                            feature: <p>Envio de mensajes y promociones</p>,
                      }, 
                      {
                            isFeaure: true,
                            feature:  <p>Campañas automatizadas via email y SMS</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Programación de recordatorios personalizados</p>,
                      },
                      {
                            isFeaure: true,
                            feature: <p>Seguimiento a campañas de mercadeo</p>,
                      },          
                ],
     },        
      
     {  title: "Acceso a marketplace de salud",
         contents: [ 
                        {
                              isFeaure: true,
                              feature: <p>Obtenga un Perfil profesional digital</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Aumente la visibilidad de su clinica y atraiga nuevos pacientes</p>,
                        },
                        {
                              isFeaure: true,
                              feature:<p>Mejore su Reputacion en linea con reviews y opiniones de sus consultas</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Publique fotos, videos y articulos (opcional) de su clinica</p>,
                        },
                        {
                              isFeaure: true,
                              feature: <p>Reciba agendamiento online de citas</p>,
                        },   
                        {
                              isFeaure: true,
                              feature: <p>Brinde atención por teleconsulta</p>,
                        }, 
                        {
                              isFeaure: true,
                              feature: <p>Liste sus procedimientos</p>,
                        },   
                        {
                              isFeaure: true,
                              feature:  <p>Acepte pagos en linea</p>,
                        },
                        {
                              isFeaure: true,
                              feature:  <p>Publicidad a través de aliados</p>
                        },             
                  ],
       },
                 
 ]