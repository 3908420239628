import React, { FC, useState, useRef, useEffect } from 'react';
import "./Price.css";
import consultingRoom from "../../assets/icons/consultorio.svg";
import enterprise from "../../assets/icons/corporativo.svg";
import DrHelpDropDownList from "../drhelp-drop-down-list/DrHelpDropDownList";
import WebPageOwnDomain from '../web-page-own-domain/WebPageOwnDomain';
import FrequentQuestions from "../frequent-questions/FrequentQuestions";
import MoreQuestions from '../more-questions/MoreQuestions';
import ReviewInPrice from '../review-in-price/ReviewInPrice';
import { consultingRoomContent, clinicContent } from "../../data/FeaturesPlansData";
import PlanTime from "../../models/PlanTime";
import { Link } from 'react-router-dom';
import EpaycoButton from '../epayco-button/EpaycoButton';
import ReactPixel from 'react-facebook-pixel';


const selectedStyleButton: React.CSSProperties = {
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: ".9rem",
    fontWeight: "bold",
    padding: "8px 24px",
    width: "120px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    height: "fit-content",
    marginInline: "8px",
    backgroundColor: "var(--branch-light-aquamarine)",
    color: "white",
    border: "1px solid var(--branch-light-aquamarine)",
}

const deselectedStyleButton: React.CSSProperties = {
      borderRadius: "8px",
      cursor: "pointer",
      fontSize: ".9rem",
      fontWeight: "normal",
      padding: "8px 24px",
      width: "120px",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      height: "fit-content",
      marginInline: "8px",
      backgroundColor: "white",
      color: "var(--branch-light-aquamarine)",
      border: "1px solid var(--branch-light-aquamarine)"
  }

  const purchaseIntent = () => {
      ReactPixel.track( "AddToCart");
      console.log("Queriendo pagar el plan")
  };

const Price: FC = () => { 
                              const [consultingRoomClickedItem, setConsultingRoomInvolvedClickedItem] = useState<number>(-1); 
                              const [clinicClickedItem, setClinicClickedItem] = useState<number>(-1);  
                              const [selectedTimeRange, setselectedTimeRange] = useState<PlanTime>(PlanTime.MONTHLY)   
                              const buttonStyle = ( time: PlanTime ) => (time === selectedTimeRange )?
                                                                        selectedStyleButton :
                                                                        deselectedStyleButton
                              
                                                                  
                                                                              let sectionRef = useRef<HTMLDivElement>(null)
                                                                              useEffect( () => {
                                                                                  
                                                                                  let removeScriptFunction = () => {};
                                                                                  let loadBabel = function(url: string, callback: (window: any, document: Document) => void) { 
                                                                                      /*  var script = document.createElement('script');
                                                                                       script.async = true;
                                                                                       script.onload = function() {  */
                                                                                           callback(window, document); 
                                                                                       /* }; 
                                                                                       script.src = url;
                                                                                       document.head.appendChild(script); */
                                                                                   }
                                                                                   
                                                                                   let getCookie = function (cname: string): string {
                                                                                       let objToday = new Date();
                                                                                       let version = objToday.toISOString()
                                                                                       .split('T')[0]
                                                                                       .split('-')
                                                                                       .join('');
                                                                                       let name = cname + '=';
                                                                                       let decodedCookie = decodeURIComponent(document.cookie);
                                                                                       let cookieArray = decodedCookie.split(';');
                                                                                       for (let i = 0; i < cookieArray.length; i++) {
                                                                                           let cookie = cookieArray[i];
                                                                                           cookie = cookie.trim();
                                                                                           if (cookie.indexOf(name) == 0) {
                                                                                             return cookie.substring(name.length, cookie.length);
                                                                                           }
                                                                                       } 
                                                                                       return version;
                                                                                   };
                                                                                       let loadWidget = function(window: any, document: Document) {
                                                                                           let _cp = {"id":"UrBbZUOkIlwK-fXoDkQ-35k5zMoHLg3VMhIl5WyI6rY","version":"1.1"};
                                                                                           let cp = document.createElement('script');
                                                                                           cp.type = 'text/javascript';
                                                                                           cp.async = true;
                                                                                           cp.src = "++cdn-widget.callpage.io+build+js+callpage.js"
                                                                                           .replace(/[+]/g, '/')
                                                                                           .replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');
                                                                                           sectionRef.current?.appendChild(cp); 
                                                                                           removeScriptFunction =  () => sectionRef.current?.removeChild(cp); 
                                                                                          /*  if (window.callpage) 
                                                                                               { alert('You could have only 1 CallPage code on your website!'); }
                                                                                           else */ { window.callpage = function(method: string) { 
                                                                                                if (method == '__getQueue') {
                                                                                                    return this.methods;
                                                                                                } else if (method) { if (typeof window.callpage.execute === 'function') { 
                                                                                                   return window.callpage.execute.apply(this, arguments);
                                                                                               } 
                                                                                                else {(this.methods = this.methods || []).push({arguments: arguments,});}}
                                                                                               };
                                                                                                window.callpage.__cp = _cp;
                                                                                                window.callpage('api.button.autoshow');
                                                                                           }
                                                                                       };
                                                                          
                                                                                       loadBabel('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js', () => loadWidget(window, document));
                                                                                       return removeScriptFunction;
                                                                              }, [])      


                              return (
                                    <section ref={sectionRef} 
                                             className='price-section-container'>      
                                            <h2 className="price-section-container__title">Planes</h2>
                                            <div className="price-section__button-container">
                                                  <div
                                                      onClick={() => setselectedTimeRange(PlanTime.ANNUAL)}
                                                      style={ buttonStyle(PlanTime.ANNUAL) }>
                                                      ANUAL                          
                                                   </div>
                                                 <div 
                                                      onClick={() => setselectedTimeRange(PlanTime.MONTHLY)}
                                                      style={ buttonStyle(PlanTime.MONTHLY )} >
                                                       MENSUAL
                                                 </div>   
                                            </div>

                                            {/* <div  className="dummy-separator"/> */}

                                            <div className="price-section__plans-container">
                                                 <div className="price-section__consulting-room-plan-container">
                                                       <div className="price-section__consulting-room-plan">
                                                            <div className="price-section__consulting-room-plan-img-container">
                                                                  <img className="price-section__consulting-room-plan-img" 
                                                                  src={consultingRoom}
                                                                  alt="ícono para el plan personal"
                                                                   />
                                                            </div>
                                                            <h3>Mi Consultorio</h3>
                                                            <p className="price-section__consulting-room-plan-for">Para profesionales independientes</p>
                                                            <div className="price-section__consulting-room-plan-price-text-container">
                                                                  <p className="price-section__consulting-room-plan-price-text">
                                                                        { (selectedTimeRange === PlanTime.MONTHLY) ? "$93.450":"$961.200" 
                                                                   }</p>
                                                                  <div className="price-section__consulting-room-plan-price-text-for">
                                                                        <p className="price-section__consulting-room-plan-by">por profesional</p>
                                                                        <p className="price-section__consulting-room-plan-price-locale">COP/{ (selectedTimeRange === PlanTime.MONTHLY) ? "MES":"AÑO" }</p>
                                                                  </div>
                                                            </div>
                                                       </div>
                                                       <div className="price-section__consulting-room-plan-features-container">
                                                       {
                                                             consultingRoomContent.map( (content, index) => <DrHelpDropDownList
                                                                                                                  title={content.title}
                                                                                                                  content={ content.contents } 
                                                                                                                  isClicked={ consultingRoomClickedItem === index}
                                                                                                                  selectionListener={() => setConsultingRoomInvolvedClickedItem(index) } key={index}/> )
                                                       }
                                                       </div>
                                                       
                                                       <div onClickCapture={() => purchaseIntent()} className="price-section__payment-epayco-button">
                                                             <EpaycoButton isMonth={selectedTimeRange === PlanTime.MONTHLY}/>
                                                       </div>
                                                 </div>
                                                 <div>

                                                 </div>
                                                 <div className="price-section__clinic-plan-container">
                                                       <div className="price-section__consulting-room-plan">
                                                            <div className="price-section__clinic-plan-img-container">
                                                                  <img className="price-section__clinic-plan-img" 
                                                                  src={enterprise}
                                                                  alt="ícono para el plan corporativo"
                                                                  />
                                                            </div>
                                                            <h3>Corporativo</h3>
                                                            <p className="price-section__consulting-room-plan-for">Para grandes organizaciones</p>
                                                            <div className="price-section__clinic-custom-plan-container">
                                                                  <p className="price-section__clinic-custom-plan">Plan personalizado</p>              
                                                            </div>
                                                            </div>
                                                            <div className="price-section__clinic-custom-plan-features-container">
                                                                  {
                                                                      clinicContent.map( (content, index) => <DrHelpDropDownList 
                                                                                                                        title={content.title} content={ content.contents } 
                                                                                                                        selectionListener={() => setClinicClickedItem(index)}
                                                                                                                        isClicked={ clinicClickedItem === index}
                                                                                                                        key={index}/> )
                                                                  }
                                                            </div>
                                                            <div className="price-section__payment-button-container">
                                                               <Link to="contact">
                                                                  <div className="price-section__payment-button">
                                                                       <p className="price-section__payment-button-text">CONTACTAR</p>       
                                                                  </div>
                                                               </Link> 
                                                            </div>
                                                                           
                                                 </div>
                                                 <div>

                                                 </div>
                                            </div>
                                            <div className="web-page-view-in-price-container">
                                                <WebPageOwnDomain/>
                                            </div>
                                            <ReviewInPrice />
                                            <div className="frequent_questions-view-in-price-container">
                                                <FrequentQuestions />                             
                                            </div>
                                            <div className="more-questions-view-in-price-container">
                                                  <MoreQuestions />
                                            </div>
                                            
                                       </section>

)
}
                                       
export default Price; 