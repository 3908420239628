import React, { FC } from 'react';
import "./BlogArticleN.css";
import polite from "../../assets/img/doctor_checking_overlay.png";

interface BlogArticleArgs {
    title: string, 
    imagePath: string, 
    description: string,
    publishedAt: string 
}

const BlogArticle3: FC<BlogArticleArgs> = ({title, imagePath, description, publishedAt}) => <article className="blog-article-section">
                                                <h2 className="blog-article-section__title">{ 
                                                    `Política de retención de Historia Clínica Electrónica en eClinic`
                                                }</h2>
                                                <p className="blog-article-section__publish-date">{
                                                    `PUBLICADO DICIEMBRE 14, 2018 POR ADMINISTRACION DOCTORHELP!`
                                                }</p>
                                                 <div className="blog-article-section__img-container">
                                                    <img className="blog-article-section__img" src={ polite } alt={ title } />
                                                </div>
                                                <div className="blog-article-section__description">
                                                    <p>{ 
                                                        `La Ley colombiana establece que cada institución médica o cada profesional de la 
                                                        salud debe almacenar copia de la Historia Clínica de sus pacientes por un periodo mínimo de 15 años, 
                                                        que se cuentan desde la última atención realizada al paciente. Describimos en este artículo los
                                                         diferentes escenarios de retención de dicha información de Historia Clínica cuando usted adquiere
                                                         eClinic.`
                                                    }</p>
                                                </div>

                                                <h3>Con un contrato de eClinic vigente</h3>

                                                <div className="blog-article-section__description">
                                                    <p>{ 
                                                        `Recordemos que eClinic es un software que se presta como servicio, eso significa que usted paga un 
                                                        alquiler mensual del producto y nosotros nos ecargamos de proporcionar toda la infraestructura necesaria
                                                         para hospedar la aplicación. Esto incluye los servidores de almacenamiento de datos. Con respecto a la 
                                                         retención de datos de Historia Clínica de sus pacientes, mientras su clínica o consultorio tenga un 
                                                         contrato vigente de eClinic, toda la información de Historia Clínica de los pacientes estará disponible
                                                          sin límite de tiempo para ser consultada y exportada desde la aplicación. No importa cuánto tiempo haya pasado,
                                                           usted siempre podrá volver a consultar todo lo que evolucionó en el expediente de un paciente con solo consultar por 
                                                           número de documento. Garantizamos la confidencialidad y seguridad de dicha información.`
                                                    }</p>
                                                </div>

                                                <h3>¿Qué pasa si cancelo o termino mi contrato de eClinic? </h3>

                                                <div className="blog-article-section__description">
                                                    <p>{ 
                                                        `Si en un momento determinado decide interrumpir su contrato de eClinic, nosotros procederemos de la siguiente 
                                                        forma:`
                                                    }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Entregaremos una copia exportada de la Historia Clínica de sus pacientes en dos formatos: Excel y PDF, 
                                                        con todos los adjuntos que ha almacenado en el producto (imágenes, documentos, radiografías, etc.). 
                                                        Esto permitirá que usted visualice, imprima o entregue al paciente su expediente completo. Tenga 
                                                        presente que la responsabilidad sobre la confidencialidad de la información ya no recaerá sobre PlusData.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `
                                                        Almacenaremos una copia en crudo de la información de Historia Clínica de los pacientes durante un periodo 
                                                        de 15 años después de concluido el contrato. Esto significa que tendremos una copia, no navegable ni visualizable, 
                                                        de la información que usted administró en eClinic y que podrá ser solicitada en formatos Excel o 
                                                        PDF en cualquier momento a PlusData. Garantizamos la confidencialidad y seguridad de esa información, 
                                                        por lo que la solicitud de dicha información estará sujeta a ciertos términos y condiciones.
                                                    ` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ 
                                                        `
                                                        Así que no importa si cuenta con un contrato vigente o no de eClinic, nosotros garantizamos que usted cumpla 
                                                        con las políticas de retención de Historia Clínica estipuladas por la regulación colombiana.
                                                        `
                                                    }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ 
                                                        `
                                                        Siéntase seguro con respecto a la confidencialidad, secuencialidad e inalterabilidad de la Historia Clínica 
                                                        de sus pacientes al suscribir un contrato de eClinic con nosotros.
                                                        `
                                                    }</p>
                                                </div>

                                            </article>;

export default BlogArticle3; 