import React, { FC } from 'react';
import "./CookieBanner.css";
import { Link } from 'react-router-dom';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

const CookieBanner: FC<{acceptCookieListener: () => void}> = ({ acceptCookieListener }) => {
    return (
        <div className="banner-container">
            <p className="banner-cookie__text">
                Usamos cookies para brindarle una mejor experiencia. Lea nuestra 
            <span className="banner-cookie__privacy-policies">
                <Link className="privacy-policies__link" to="/cookie_policy">
                    {" Politica de cookies."}
                </Link>
            </span>
            </p>
            <div className="cookie-banner_accept-button"
                 onClick={() =>  acceptCookieListener() }>
                <p>Aceptar</p>
            </div>
            <div className="banner-cookie__privacy-policies-button-close" onClick={() =>  acceptCookieListener() }>
                     <Icon className='hamburger-menu__main-page-icon'
                         path={mdiClose }
                         title="close cookie dialog"
                         size={1.25}
                         horizontal
                         vertical
                         rotate={180}
                         color="#FFFFFF"/>

            </div>
        </div>
    )
}

export default CookieBanner;