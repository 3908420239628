import React, { FC } from 'react';
import "./TellToExpertButton.css";
import { Link } from 'react-router-dom';

const TellToExpertButton: FC = () => <a href="https://wa.me/573165406875" className="to-expert-button-link">
                                        <button className="to_expert-button">
                                            Hablar con un experto
                                        </button>
                                     </a>;



export default TellToExpertButton;