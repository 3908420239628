import React, { FC } from 'react';
import "./AllServicesIcons.css";
import ServiceEntry, { ServiceEntryArg } from "../service-entry/ServiceEntry";
import appointmens from "../../assets/icons/appointments.png";
import cash from "../../assets/icons/cash_paper.png";
import clinicHistory from "../../assets/icons/clinic_history.png";
import email from "../../assets/icons/email_health.png";
import finance from "../../assets/icons/finance.png";
import videocall from "../../assets/icons/pc_videocall.png";
import prescriptions from "../../assets/icons/prescriptions.png";
import stock from "../../assets/icons/stock.png";
import telehealth from "../../assets/icons/telehealth.png";
import videocallMobile from "../../assets/icons/videocall.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const services: ServiceEntryArg[] = 
[
    {
        imgPath: clinicHistory,
        title: "Historia clínica"
},
    {
        imgPath: appointmens,
        title: "Agenda de citas"
},
    {
        imgPath: videocall,
        title: "Telemedicina "
},
    {
        imgPath: prescriptions,
        title: "Prescripción y órdenes"
},
    {
        imgPath: email,
        title: "RIPS. Reportes  de ley y a medida"
},
    {
        imgPath: telehealth,
        title: "Notificaciones y marketing"
},
    {
        imgPath: videocallMobile,
        title: "Su Perfil digital en Marketplace"
},
    {
        imgPath: stock,
        title: "Gestión de inventarios "
},
    {
        imgPath: finance,
        title: "Finanzas"
},
{
    imgPath: cash,
    title: "Facturación y cotizaciones"
}
]

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    arrows: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    centerPadding: '100px',
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
  };

const AllServicesIcons: FC = () => <section className="all-services-icons-container">
                                        <Slider {...settings}>
                                            {
                                                services.map( (service, index) => <ServiceEntry {...service} key={index}/> )
                                            }
                                        </Slider>
                                    </section>
                              


export default AllServicesIcons;