import React, { FC } from 'react';
import "./BlogArticleN.css";
import legal from "../../assets/img/legalissues.jpg"

interface BlogArticleArgs {
    title: string, 
    imagePath: string, 
    description: string,
    publishedAt: string 
}

const BlogArticle2: FC<BlogArticleArgs> = ({title, imagePath, description, publishedAt}) => <article className="blog-article-section">
                                                <h2 className="blog-article-section__title">{ `Aspectos legales de la Historia Clínica Electrónica` }</h2>
                                                <p className="blog-article-section__publish-date">
                                                    {`PUBLICADO ABRIL 01, 2019 POR ADMINISTRACION DOCTORHELP!`}
                                                    </p>
                                                 <div className="blog-article-section__img-container">
                                                    <img className="blog-article-section__img" src={ legal } alt={ title } />
                                                </div>
                                                <div className="blog-article-section__description">
                                                    <p>{ `La resolución 1995 de 1999 definió la Historia Clínica como el documento en el cual 
                                                    "se registran cronológicamente las condiciones de salud del paciente, los actos médicos y 
                                                    los demás procedimientos ejecutados por el equipo de salud que interviene en su atención". 
                                                    La norma también define algunas características fundamentales de la Historia Clínica. Las repasaremos 
                                                    en este artículo al tiempo que le indicamos cómo están implementadas en eClinic para el correcto 
                                                    cumplimiento de la norma:` }</p>
                                                </div>

                                                <h3>Secuencialidad</h3>

                                                <div className="blog-article-section__description">
                                                    <p>{ `Los registros de la prestación de servicios en salud se deben consignar en la secuencia 
                                                    cronológica en que ocurrió la atención. Esto significa que además de ordenarlos de forma 
                                                    cronológica, deben también disponer de la información de fecha y hora en la que se presentan. 
                                                    eClinic garantiza esta característica al asociar la evolución de la atención al paciente a las 
                                                    citas médicas que usted puede programar en la aplicación. Al existir esta integración entre agenda del
                                                     paciente e Historia Clínica, el sistema le permite conservar la secuencia de procedimientos médicos 
                                                     que realiza a un paciente.` }</p>
                                                </div>

                                                <h3>Disponibilidad</h3>

                                                <div className="blog-article-section__description">
                                                    <p>{ `Se refiere a la posibilidad de utilizar la historia clínica en el momento en que se necesita, con las 
                                                    limitaciones que impone la Ley. Esto es, que se pueda acceder a la Historia Clínica del paciente en todo momento,
                                                     pero que además se pueda acceder solo por el personal autorizado. Por tratarse de una aplicación web, que puede 
                                                     ser usada desde cualquier dispositivo con conexión a internet, eClinic le permitirá gozar de una historia 
                                                     clínica siempre disponible y que podrá revisar fácilmente. Pero además, por tratarse de una aplicación con altos 
                                                     estándares de seguridad, usted tendrá la garantía de que solo el personal que usted autorice, pueda visualizar la 
                                                     historia clínica de sus pacientes.` }</p>
                                                </div>

                                                <h3>Inalterabilidad</h3>

                                                <div className="blog-article-section__description">
                                                    <p>{ `La norma establece que la Historia Clínica debe estar provista de mecanismos de seguridad que imposibiliten la 
                                                    incorporación de modificaciones una vez que se registren y guarden los datos. Pues bien, eClinic será el custodio de 
                                                    la inalterabilidad de la información en tanto que no permite que un registro de evolución al paciente se modifique. Sin embargo, 
                                                    puesto que los errores existen y usted puede equivocarse de buena fe al momento de ingresar un registro en la Historia Clínica
                                                     de sus pacientes, eClinic le permitirá solicitar modificación o eliminación de lo que ha registrado. Al momento de hacerlo, 
                                                     le pedirá información que permita explicar la causa de la solicitud. Esta trazabilidad será almacenada en el sistema y solo podrá 
                                                     ser accedida por las autoridades competentes, en caso de que lo requieran. Con esto se garantiza que no se harán modificaciones 
                                                     ilegales a la Historia Clínica electrónica, cumpliendo la Ley, pero también se dará la suficiente flexibilidad que permita anticipar 
                                                     errores que se puedan cometer.` }</p>
                                                </div>

                                                <div className="blog-article-section__description">
                                                    <p>{ `Como puede observar, eClinic le ayuda en la implementación de los aspectos legales de la Historia Clínica al tiempo 
                                                    que le proporciona una solución sumamente amigable e integral, que le permite administrar su clínica o consultorio de manera holística.
                                                     Revise adicionalmente nuestras políticas de retención de Historia Clínica, con o sin contrato, otro de los elementos normativos 
                                                     importantes a tener en cuenta.` }</p>
                                                </div>

                                                
                                            </article>;

export default BlogArticle2; 