import React, { FC } from "react";
import BlogArticle from "../components/blog-article/BlogArticle";
import BlogEntries from "../data/BlogEntriesData";



const BlogPages: FC = () => {  
    return (
                        <section >
                           { BlogEntries.map( (entry, index)  => <BlogArticle key={index} { ...entry} /> ) }                       
                        </section> 
    ) 
}
    

export default BlogPages;                            