import React, { FC } from 'react';
import "./ServiceEntry.css";

export interface ServiceEntryArg{
    imgPath: string,
    title: string
}

const ServiceEntry: FC<ServiceEntryArg> = ({ imgPath, title}) => <article className="service-section__container">
                                                                    <div className="service-section__icon-container">
                                                                        <img className="service-section__icon" src={imgPath} alt={title}/>
                                                                    </div>
                                                                    <div className="service-section__title">
                                                                        <p>{title}</p>
                                                                    </div>
                                                                  </article>;

export default ServiceEntry;                               
